<template>
  <div class="card">
    <div class="card-header">
      <h4 class="title">User Details</h4>
    </div>
    <div class="card-body">
      <div v-if="loading" class="spinner-container">
        <double-bounce size="60px"></double-bounce>
      </div>
      <div v-if="!loading && loadingError" class="alert alert-warning">
        <span>{{ loadingError }}</span>
      </div>
      <form v-if="!loading && user.id">
        <div class="row">
          <div class="col-md-6">
            <fg-input
              type="email"
              label="Email address"
              placeholder="Email"
              :disabled="true"
              v-model="user.emailAddress"></fg-input>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="control-label">&nbsp;</label>
              <br />
              <!-- <p-button v-if="!user.emailAddressIsVerified"
                        style="margin: 0 10px 0 0"
                        class="btn-warning"
                        :disabled="verificationNotificationSent"
                        @click="resendEmailVerificationNotification()">
                <span v-if="verificationNotificationSending">Wait&hellip;</span>
                <span v-if="!verificationNotificationSending && !verificationNotificationSent">Verify</span>
                <span v-if="!verificationNotificationSending && verificationNotificationSent">Sent</span>
              </p-button>
              <p-button style="margin: 0 10px 0 0"
                        class="btn-default"
                        @click="showUpdateEmailDialog()">
                        Update
              </p-button>-->
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <fg-input
              type="password"
              label="Password"
              placeholder="****************"
              value="****************"
              :disabled="true"></fg-input>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="control-label">&nbsp;</label>
              <br />
              <p-button
                style="margin: 0 10px 0 0"
                class="btn-default"
                @click="showUpdatePasswordDialog()">Update</p-button>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <fg-input
              type="text"
              name="firstName"
              data-vv-as="first name"
              data-vv-delay="1000"
              label="First Name"
              placeholder="First Name"
              v-model="user.firstName"
              v-validate="formValidators.firstName"
              disabled="true"
              :error="fieldError('firstName')"></fg-input>
          </div>
          <div class="col-md-6">
            <fg-input
              type="text"
              name="lastName"
              data-vv-as="last name"
              data-vv-delay="1000"
              label="Last Name"
              placeholder="Last Name"
              v-model="user.lastName"
              v-validate="formValidators.lastName"
              disabled="true"
              :error="fieldError('lastName')"></fg-input>
          </div>
        </div>

        <div class="text-right">
          <!-- <button v-if="!saving" type="submit" class="btn btn-info btn-fill btn-wd" @click.prevent="validate">
            Update Profile &nbsp;<i class="fa fa-arrow-right"></i>
          </button>
          <button v-if="saving" disabled class="btn btn-info btn-fill btn-wd">
            <i class="fa fa-spin fa-circle-o-notch"></i>&nbsp;
            Please wait&hellip;
          </button>-->
        </div>
        <div class="clearfix"></div>
        <!-- <p>
          If you no longer want your user account, you can <a href="#" @click="showDeleteUserDialog()">delete it</a>.
        </p>-->
      </form>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import { DoubleBounce } from "vue-loading-spinner";
import swal from "sweetalert2";
import isEmail from "isemail";
import moment from "moment";

import getDeviceId from "src/device.js";

export default {
  components: {
    DoubleBounce,
  },
  props: {
    user: Object,
  },
  data() {
    return {
      formValidators: {
        firstName: {
          required: false,
          min: 2,
        },
        lastName: {
          required: false,
          min: 1,
        },
      },
      validationErrors: {},
      saving: false,
      loading: false,
      verificationNotificationSending: false,
      verificationNotificationSent: false,
      loadingError: null,
    };
  },
  methods: {
    resendEmailVerificationNotification() {
      this.verificationNotificationSending = true;
      this.axios
        .put(`/v1/users/${this.user.id}/email`, {
          address: this.user.emailAddress,
        })
        .then((response) => {
          this.verificationNotificationSending = false;
          this.verificationNotificationSent = true;

          swal({
            type: "success",
            title: "Verification email sent",
            text: "Please check your email inbox and follow the verification instructions.",
          });
        })
        .catch((error) => {
          this.verificationNotificationSending = false;
          const errorMessage = "Error sending verification email";
          this.$notify({
            message: errorMessage,
            timeout: 2000,
            icon: "fa fa-exclamation-triangle",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "error",
          });
        });
    },
    showDeleteUserDialog() {
      swal({
        type: "warning",
        input: "text",
        title: "Delete user account",
        text: "Your user account will be permanently deleted. \n Please type DELETE to confirm.",
        footer: "Caution: this action cannot be undone.",
        showCancelButton: true,
        inputValidator: (value) => {
          if (!value || value.toUpperCase() !== "DELETE") {
            return "Type DELETE to confirm.";
          }
        },
        preConfirm: (input) => {
          return this.axios
            .delete(`/v1/users/${this.user.id}`)
            .then((response) => {
              return response;
            })
            .catch((error) => {
              swal.showValidationMessage(`Deletion failed: ${error.message}`);
            });
        },
      }).then((result) => {
        if (result.value) {
          return swal({
            type: "success",
            title: "User account deleted",
            text: "You will now be logged out.",
            preConfirm: (input) => {
              return this.$store.dispatch("logout");
            },
          });
        }
      });
    },
    showUpdateEmailDialog() {
      swal({
        type: "question",
        title: "What's your new email address?",
        input: "email",
        inputValidator: (value) => {
          return !isEmail.validate(value) && "Invalid email address";
        },
        showCancelButton: true,
        confirmButtonText: "Update",
        showLoaderOnConfirm: true,
        preConfirm: (input) => {
          return this.axios
            .put(`/v1/users/${this.user.id}/email`, { address: input })
            .then((response) => {
              if (response.data && response.data.address) {
                this.user.email = response.data;
                // this.$auth.user().email = response.data; // TODO: remove
                this.$store.commit(
                  "SET_USER",
                  _.assign(this.user, { email: response.data })
                );
              }
              return response;
            })
            .catch((error) => {
              swal.showValidationMessage(`Update failed: ${error.message}`);
            });
        },
        allowOutsideClick: () => !swal.isLoading(),
      }).then((result) => {
        if (result.value) {
          swal({
            type: "success",
            title: "Email address updated",
            text: "Please check your email inbox and follow the verification instructions.",
          });
        }
      });
    },
    showUpdatePasswordDialog() {
      const minPasswordLength = 8;
      swal({
        title: "Update your password",
        text: "Your password should be at least 8 characters long",
        html: `<form id="password-update" class="text-left" style="margin-top: 1em;">
          <div class="form-group">
            <label for="current-password">Current password</label>
            <input id="current-password" type="password" class="form-control form-control-lg" autocomplete="current-password">
            <small class="help">The password you currently use to log in</small>
          </div>

          <div class="spacer"></div>

          <div class="form-group">
            <label for="new-password">New password</label>
            <input id="new-password" type="password" class="form-control form-control-lg" autocomplete="new-password">
            <small class="help">
              Choose a new password:
              <ul>
                <li>
                  Minimum ${minPasswordLength} characters.
                </li>                     
                <li>
                  No more than 2 recurring characters eg. \'AAA\', \'111\', \'$$$\'.'
                </li>                
                <li>
                  No more than 2 sequential letters or numbers eg. \'ABC\', \'123\'.'
                </li>
              </ul>
              </small>
          </div>

          <div class="spacer"></div>

          <div class="form-group">
            <label for="new-password-confirm">New password (again)</label>
            <input id="new-password-confirm" type="password" class="form-control form-control-lg" autocomplete="new-password">
            <small class="help">Please re-type your new password.</small>
          </div>
        </form>`,
        showCancelButton: true,
        confirmButtonText: "Update",
        showLoaderOnConfirm: true,
        focusConfirm: false,
        allowOutsideClick: () => !swal.isLoading(),
        preConfirm: () => {
          const currentPassword =
            document.getElementById("current-password").value;
          const newPassword = document.getElementById("new-password").value;
          const newPasswordConfirmation = document.getElementById(
            "new-password-confirm"
          ).value;

          // check password is 8 chars or more
          if (newPassword.length < minPasswordLength) {
            return swal.showValidationMessage(
              `New password must be at least ${minPasswordLength} characters long.`
            );
          }

          if (newPassword !== newPasswordConfirmation) {
            return swal.showValidationMessage(
              `Oops, your passwords don't match.`
            );
          }

          function delay(t) {
            return new Promise(function (resolve) {
              setTimeout(resolve, t);
            });
          }

          return this.axios
            .put(`/v1/users/${this.user.id}/password`, {
              currentPassword,
              newPassword,
            })
            .then((response) => {
              // wait a short while to avoid race conditions
              return delay(2000);
            })
            .then(() => {
              // now need to get a new refresh token, since the old one will be considered invalid
              return this.axios.post("/v1/tokens", {
                type: "refresh",
                grantType: "password",
                emailAddress: this.user.emailAddress,
                password: newPassword,
                deviceIdentifier: getDeviceId(),
              });
            })
            .then((response) => {
              // set the refresh token expiry
              const expSeconds =
                moment(response.data.expiresAt).unix() - moment().unix();
              this.$ls.set(
                "refresh_token_expires_at",
                response.data.expiresAt,
                expSeconds * 1000
              );

              this.$store.dispatch("userUpdated");
              return true;
            })
            .catch((error) => {
              if (error.response.data.message && error.response.data.message == 'Password appears in list of compromised passwords') {
                return swal.showValidationMessage(error.response.data.message);
              }

              if (error.response.data.message && error.response.data.message == 'Invalid format: Password contains words from prohibited list') {
                return swal.showValidationMessage(error.response.data.message);
              }

              if (error.response.data.message && error.response.data.message == 'Invalid format: Password contains three or more recurring characters') {
                return swal.showValidationMessage(error.response.data.message);
              }

              if (error.response.data.message && error.response.data.message == 'Invalid format: Password contains three or more sequential letters') {
                return swal.showValidationMessage(error.response.data.message);
              }

              if (error.response.data.message && error.response.data.message == 'Invalid format: Password contains three or more sequential numbers') {
                return swal.showValidationMessage(error.response.data.message);
              }

              let errMsg = "Error updating password";
              
              if (error.response && error.response.data) {
                if (error.response.data.message) {
                  errMsg += `: ${error.response.data.message}.`;
                }

                // try to do field-level error handling
                if (
                  error.response.data.errors &&
                  error.response.data.errors.length > 0
                ) {
                  _.each(error.response.data.errors, (err) => {
                    if (err.messages && err.messages.length > 0) {
                      errMsg += `<br>${err.messages[0]}`;
                    }
                  });
                }
              }
              swal.showValidationMessage(errMsg);
            });
        },
      }).then((result) => {
        if (result.value) {
          swal({
            type: "success",
            title: "Password updated",
          });
        }
      });
    },
    updateUser() {
      this.saving = true;
      const body = {
        firstName: this.user.firstName,
        lastName: this.user.lastName,
      };
      this.axios
        .patch(`/v1/users/${this.user.id}`, body)
        .then((response) => {
          this.saving = false;
          // this.user = response.data;
          this.$store.commit("SET_USER", response.data);

          this.$notify({
            message: "Profile updated",
            timeout: 2000,
            icon: "fa fa-check",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "success",
          });
        })
        .catch((error) => {
          this.saving = false;
          let errorMessage = "Error updating profile";
          this.$notify({
            message: errorMessage,
            timeout: 2000,
            icon: "fa fa-exclamation-triangle",
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "error",
          });
        });
    },
    validate() {
      this.$validator.validateAll().then((isValid) => {
        this.$emit("on-submit", this.registerForm, isValid);
        if (isValid) {
          this.updateUser();
        }
      });
    },
    fieldError(fieldName) {
      // clientside
      if (this.errors.first(fieldName)) {
        return this.errors.first(fieldName);
      }

      // serverside
      const fieldError = _.find(this.validationErrors, (item) => {
        return _.includes(item.field, fieldName);
      });

      if (!fieldError) {
        return false;
      } else if (fieldError.messages) {
        return fieldError.messages;
      } else {
        return true;
      }
    },
  },
  beforeMount() {
    if (!this.user) {
      this.loading = true;
      this.axios
        .get("/v1/users/current")
        .then((response) => {
          this.loading = false;
          this.user = response.data;
        })
        .catch((error) => {
          this.loading = false;
          this.loadingError = "Failed to load user";
        });
    }
  },
};
</script>
<style></style>
